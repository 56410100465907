/* global PAYUNI_SDK */
import React, {
  Fragment,
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import {Button} from 'antd';
import styled from 'styled-components';
import CheckoutButton from '../../Templates/OrderDetail/CheckoutButton';
import {dateUtil} from '../../Utils';
import {Context} from '../../AppContext';
import {message} from 'antd';
import PaymentOffline from '../../Modals/PaymentOffline';
const appConfig = require('../../data.json');

export default function PaymentInfo({type, order, id, onUpdate}) {
  const app = useContext(Context);
  const {profile} = app.state;

  const renderContent = () => {
    switch (type) {
      case 'atm':
        return <ATMInfo order={order} />;
      case 'cvs':
        return <CVSInfo order={order} />;
      case 'line_pay':
        return <LinePayInfo order={order} />;
      case 'credits':
        return <CreditsInfo order={order} credits={profile.credits} />;
      case 'credit':
        return <CreditInfo order={order} actions={app.actions} id={id} />;
      case 'offline':
        return (
          <OfflineInfo
            order={order}
            actions={app.actions}
            onUpdate={onUpdate}
          />
        );
      case 'default':
        return <CustomInfo order={order} />;
      default:
        return null;
    }
  };
  return <Fragment>{renderContent()}</Fragment>;
}

const ATMInfo = ({order}) => {
  return (
    <Fragment>
      {order.payment_status === 'code_generated' ? (
        <Section>
          <h2>ATM 代碼轉帳</h2>
          <p>
            轉帳帳號：銀行代碼 {order.bank_code} - 帳號 {order.code_no}
          </p>
          <p>
            繳費期限：{dateUtil.format(order.pay_deadline, 'YYYY-MM-DD HH:mm')}
          </p>
          <p>
            詳細繳費通知可查收E-mail信箱【統一金流
            PAYUNi】，付款完成後統一金流將發送收款通知信至您的E-mail信箱。
          </p>
        </Section>
      ) : (
        <CheckoutButton order={order}>前往付款</CheckoutButton>
      )}
    </Fragment>
  );
};

const CVSInfo = ({order}) => {
  return (
    <Fragment>
      {order.payment_status === 'code_generated' ? (
        <Section>
          <h2>超商代碼繳費 (非貨到超商付款)</h2>
          <p>超商繳費代碼：{order.code_no}</p>
          <p>
            繳費期限：{dateUtil.format(order.pay_deadline, 'YYYY-MM-DD HH:mm')}
          </p>
          <p>
            詳細繳費通知可查收E-mail信箱【統一金流
            PAYUNi】，付款完成後統一金流將發送收款通知信至您的E-mail信箱。
          </p>
        </Section>
      ) : (
        <CheckoutButton order={order}>前往付款</CheckoutButton>
      )}
    </Fragment>
  );
};

const LinePayInfo = ({order}) => {
  return (
    <Fragment>
      {order.payment_status !== 'success' && (
        <Section>
          <h2>LINE PAY 付款</h2>
          <p>
            請使用LINE帳號或掃描行動條碼登入。若您已經是 LINE Pay
            會員，點擊付款可直接開啟應用程式進行付款。
          </p>
        </Section>
      )}
      <CheckoutButton order={order}>LINE PAY 付款</CheckoutButton>
    </Fragment>
  );
};

const CreditsInfo = ({credits, order}) => {
  return (
    <Fragment>
      <Section>
        <h2>點數付款</h2>
        <p>您的會員點數餘額：{credits} 點</p>
        <p>
          若點數餘額不足請前往：會員中心 &gt; 帳戶管理 &gt; 點數加值 &gt;
          購買點數 (點數加值等於預先支付費用，1點＝新台幣1元。){' '}
        </p>
      </Section>
      <CheckoutButton order={order}>點數扣點付款</CheckoutButton>
    </Fragment>
  );
};

const CreditInfo = ({order, actions, id}) => {
  const initOption = {
    env: 'P', // 預設 P 為正式環境，若要修改環境，請改為 S
    useHost: true, // 是否使用分層，若為店家開放分層請填寫true
    elements: {
      // 設定綁定欄位 ID
      CardNo: 'put_card_no', // 信用卡號碼
      CardExp: 'put_card_exp', // 到期日
      CardCvc: 'put_card_cvc', // 末三碼
      CardTokenType: 'put_token_type', // 使用的3D或非3D提供非必填(非必要)
    },
    // 預設 elements style，亦可自行定義 style
    style: {
      color: '#9b9b9b',
      errorColor: appConfig.colors.error,
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: 2,
    },
  };

  const [token, setToken] = useState(null);
  const [SDK, setSDK] = useState(null);

  useEffect(() => {
    if (order.display_state === 'payment_waiting') {
      const fetchToken = async () => {
        const token = await actions.getIframeToken(id);
        setToken(token);
      };
      fetchToken();
    }
  }, [order, id, actions]);

  useEffect(() => {
    if (token) {
      const SDK = PAYUNI_SDK.createSession(token, initOption);
      setSDK(SDK);

      const startProcess = async () => {
        try {
          await SDK.start();
        } catch (error) {
          console.log(error);
        }
      };
      startProcess();
    }
    return () => {
      console.log(PAYUNI_SDK);
    };
  }, [token]);

  const handleSubmit = async () => {
    if (SDK) {
      try {
        actions.setLoading(true);
        let {Status, EncryptInfo} = await SDK.getTradeResult();
        if (Status === 'SUCCESS') {
          message.success('付款中，請稍候');
          const result = await actions.getIframe3DUrl(EncryptInfo);
          window.open(result, '_self');
          message.success('付款中，請稍候');
        }
      } catch (error) {
        message.error(error.message || '付款失敗，請聯絡客服人員');
      }
      actions.setLoading(false);
    }
  };

  return (
    order.display_state === 'payment_waiting' && (
      <Fragment>
        <Section>
          <h2>信用卡付款</h2>
          <CreditWrapper>
            <StyledInputWrapper id="put_card_no" />
            <StyledInputWrapper id="put_card_exp" />
            <StyledInputWrapper id="put_card_cvc" />
          </CreditWrapper>
        </Section>
        <StyledButton onClick={() => handleSubmit()}>信用卡付款</StyledButton>
      </Fragment>
    )
  );
};

const OfflineInfo = ({order, actions, onUpdate}) => {
  const [paymentOfflineModal, setPaymentOfflineModal] = useState(false);
  const editOrderRemit = useCallback(
    async (values) => {
      await actions.editOrderRemit({
        id: order?.id,
        ...values,
      });
      onUpdate();
    },
    [order, actions],
  );

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          columnGap: '14px',
          width: '100%',
        }}>
        <Section flex="1">
          <h2>臨櫃匯款收款資訊</h2>
          <StyledP>收款戶名：ＯＯＯＯ印刷有限公司</StyledP>
          <StyledP>收款帳號：0000-111-222222</StyledP>
          <StyledP>
            轉帳金額請與訂單金額一致(手續費需外加)，請勿自行扣除轉帳手續費。
          </StyledP>
        </Section>
        <Section flex="1">
          <h2>回填匯款資訊</h2>
          <StyledP>匯款戶名：{order.remittance_account}</StyledP>
          <StyledP>匯款帳號後五碼：{order.remittance_number}</StyledP>
          <StyledP>
            匯款時間：{dateUtil.format(order.remitted_time, 'YYYY-MM-DD HH:mm')}
          </StyledP>
        </Section>
      </div>
      <StyledButton onClick={() => setPaymentOfflineModal(true)}>
        轉款完成回填匯款資訊
      </StyledButton>
      <PaymentOffline
        visible={paymentOfflineModal}
        // onConfirm={() => setPaymentOfflineModal(false)}
        values={{
          remittance_account: order.remittance_account,
          remittance_number: order.remittance_number,
          remitted_time: order.remitted_time,
        }}
        onCancel={() => setPaymentOfflineModal(false)}
        onEdit={editOrderRemit}
      />
    </Fragment>
  );
};

const CustomInfo = ({order}) => {
  return (
    <div
      style={{
        marginTop: '20px',
      }}>
      <CheckoutButton order={order}>前往付款</CheckoutButton>
    </div>
  );
};

const Section = styled.div`
  background-color: #fafafa;
  border-radius: 10px;
  padding: 16px 22px;
  color: #505050;
  flex: 0 0 ${(props) => props.flex || '100%'};
  width: ${(props) => props.width || '100%'};
  margin: 16px 0 0 0;
  h2 {
    font-size: 16px;
    font-weight: 500;
  }
  p {
    font-size: 14px;
  }
`;

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 45px;
  border-radius: 10px;
  background-color: #505050;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
`;

const CreditWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-wrap: wrap;
  }
`;

const StyledInputWrapper = styled.div`
  padding: 10px 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  margin-right: 8px;
  &#put_card_no {
    max-width: 304px;
  }
  &#put_card_exp {
    max-width: 204px;
  }
  &#put_card_cvc {
    max-width: 94px;
  }
  @media screen and (max-width: ${appConfig.breakpoints.lg}px) {
    flex-wrap: wrap;
    &#put_card_no {
      max-width: 100%;
      flex: 0 0 100%;
      margin: 0 0 8px 0;
    }
    &#put_card_exp {
      max-width: 204px;
      flex: 0 0 204px;
    }
    &#put_card_cvc {
      max-width: none;
      flex: 1;
      margin-right: 0;
    }
  }
`;

const StyledP = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
  line-height: 2;
`;
